<template>
  <div>
    <el-dialog title="模板详情录入"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="520px"
               :before-close="handleClose">
      <el-form :model="question_data"
               ref="formRef"
               :rules="rules"
               label-width="130px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="是否组别">
              <el-switch v-model="isGroup"
                         active-color="#13ce66"
                         inactive-color="#ff4949"
                         active-text="是"
                         inactive-text="否">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="题号"
                          prop="question_number">
              <el-input placeholder="请输入题号"
                        v-model="question_data.question_number"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24"
                  v-show="isGroup">
            <el-form-item label="组别ID"
                          prop="group_id">
              <el-input placeholder="请输入总分组别ID"
                        v-model="question_data.group_id"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="组别总分"
                          prop="group_score"
                          v-show="isGroup">
              <el-input placeholder="请输入组别总分"
                        v-model="question_data.group_score"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="题目类型"
                          prop="question_type_id">
              <el-select v-model="question_data.question_type_id"
                         style="width:100%"
                         multiple
                         placeholder="请选择">
                <el-option v-for="item in typeList"
                           :key="item.question_type_id"
                           :label="item.question_type_name"
                           :value="item.question_type_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="题目类别"
                          prop="question_category">
              <el-select v-model="question_data.question_category"
                         multiple
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in categoryList"
                           :key="item.question_category_id"
                           :label="item.question_category_name"
                           :value="item.question_category_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10"
                v-show="isGroup">
          <el-col :span="24">
            <el-form-item label="最大分数"
                          prop="max_score">
              <el-input placeholder="请输入最大分数"
                        v-model="question_data.max_score"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10"
                v-show="isGroup">
          <el-col :span="24">
            <el-form-item label="最小分数"
                          prop="min_score">
              <el-input placeholder="请输入最小分数"
                        v-model="question_data.min_score"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10"
                v-show="!isGroup">
          <el-col :span="24">
            <el-form-item label="固定分数"
                          prop="fixed_score">
              <el-input placeholder="请输入固定分数"
                        v-model="question_data.fixed_score"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      isGroup: false,
      form: {
        // 'status': 1
      },
      rowInfo: {}, //组卷规则模板信息
      question_data: {

      },
      typeList: [],//题目类型
      categoryList: [],//题目类别
      // subjectOptions: [],
      // districtOptions: [],
      rules: {
        question_number: [
          { required: true, message: '请输入题号', trigger: 'blur' },
        ],
        question_type_id: [
          { required: true, message: '请选择题目类型', trigger: 'change' },
        ],
        question_category: [
          { required: true, message: '请选择题目类别', trigger: 'change' },
        ],
      }
    }
  },
  methods: {
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    save () {
      this.$refs.formRef.validate(val => {
        console.log(this.question_data)
        if (this.isGroup) {
          if (!this.question_data.group_id) {
            this.$message.warning("组别题目需输入组别id")
          } else if (!this.question_data.group_id) {
            this.$message.warning("组别题目需输入组别分数")
          }
        } else {
          if (!this.question_data.fixed_score) {
            this.$message.warning("请输入固定分数")
          }
        }
        if (!val) return
        // console.log("类型", this.question_data.question_type_id)
        // console.log("类别1", this.question_data.question_category)
        this.question_data.question_type_id = this.question_data.question_type_id.join()
        this.question_data.question_category = this.question_data.question_category.join()
        this.question_data.fixed_score = Number(this.question_data.fixed_score)

        // console.log("类型", this.question_data.question_type_id)

        this.$http({
          url: '/api/v2/paper/rule_question',
          method: 'post',
          data: {
            paper_rule_id: Number(this.rowInfo.paper_rule_id),
            question_data: [this.question_data]
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.$parent.initData()
          this.handleClose()
        })
      })
    },
    // 获取题目类型
    getPaperType () {
      console.log(this.rowInfo.subject_id, "aa")
      let params = {
        subject_id: this.rowInfo.subject_id,
        paper_district_id: this.rowInfo.paper_district_id
      }
      this.$http({
        url: '/api/v2/paper/question_district_type',
        method: 'get',
        params
      }).then(res => {
        this.typeList = res.data
      })
    },

    // 根据科目，地区获取题目类别
    getPaperCategory () {
      let params = {
        subject_id: this.rowInfo.subject_id,
        paper_district_id: this.rowInfo.paper_district_id
      }
      this.$http({
        url: '/api/v2/paper/question_district_category',
        method: 'get',
        params
      }).then(res => {
        this.categoryList = res.data
      })
    }
  },
  watch: {
    rowInfo: {
      handler () {
        this.getPaperType()
        this.getPaperCategory()
      },
      deep: true
    }
  },
  mounted () {
    // this.getPaperType()
    // this.getPaperCategory()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
</style>