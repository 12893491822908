<template>
  <div>
    <el-row :gutter="10"
            style=" margin-bottom: 10px;">

      <el-form label-width="80px">
        <el-col :span="6">
          <el-form-item label="科目"
                        prop="subject_id">
            <el-select v-model="form.subject_id"
                       clearable
                       filterable
                       @change="search"
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="试卷地区"
                        prop="paper_district_id">
            <el-select v-model="form.paper_district_id"
                       style="width:100%"
                       filterable
                       clearable
                       @change="search"
                       placeholder="请选择">
              <el-option v-for="item in districtOptions"
                         :key="item.value"
                         :label="item.paper_district_name"
                         :value="item.paper_district_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-form>

      <el-col :span="12">
        <el-button type="primary"
                   @click="add">添加</el-button>
        <el-button type="primary"
                   @click="importFile">导入</el-button>
      </el-col>

    </el-row>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <pagination ref="pagination"
                :isInit="false"
                :get-data="initData"
                :nowPage.sync="page"
                :nowSize.sync="size"
                :total="totalElements" />

    <!-- 新增组卷规则模板弹窗 -->
    <TemplateDialog ref="templateDialogRef"
                    :subjectOptions="subjectList"
                    :districtOptions="districtOptions" />
    <DetailDialog ref="detailDialogRef" />
  </div>
</template>

<script>
import { getRuleList, delRule } from '@/api/paperRuleTemplate.js'
import { getAllSubject } from '@/api/subject.js'
import TemplateDialog from './component/TemplateDialog.vue'
import DetailDialog from './component/DetailDialog.vue'
export default {
  data () {
    return {
      tableColumns: [
        { prop: 'paper_rule_id', align: 'center', label: '规则模板ID' },
        { prop: 'rule_name', align: 'center', label: '规则模板名称' },
        { prop: 'create_time', align: 'center', label: '创建时间' },
        { prop: 'edit_time', align: 'center', label: '修改时间' },
        { prop: 'status', align: 'center', label: '状态', isStatus: true },
        { prop: 'subject_name', align: 'center', label: '科目' },
        { prop: 'paper_district_name', align: 'center', label: '地区' },
        {
          prop: 'd', align: 'center', label: '操作', width: '180', type: 'img', btns: [
            // { content: '组卷规则模板详情录入', type: 'addDetail', event: 'toAddDetail' },
            { content: '修改组卷规则模板', type: 'edit', event: 'onEdit' },
            { content: '组卷规则模板详情', type: 'look', event: 'toDetail' },
            { content: '试卷详情', type: 'look', event: 'toPaper' },
            { content: '删除', type: 'del', event: 'onDelete' },
          ]
        },
      ],
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      subjectList: [],
      districtOptions: [],
      form: {
        subject_id: '',
        paper_district_id: ''
      },
      heightLightIndex: ''
    }
  },
  components: {
    TemplateDialog, DetailDialog
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    getClass (prop, value) {
      if (prop == 'status') {
        if (value == 1) {
          return 'pass'
        } else if (value == 2) {
          return 'notpass'
        }

      }
    },
    getValue (prop, value) {
      if (prop == 'status') {
        if (value == 1) {
          return '正常'
        } else if (value == 2) {
          return '停止'
        }
      }
    },
    async getSubjectList () {
      const { data } = await getAllSubject()
      this.subjectList = data.list
    },
    getDirList () {
      // 获取试卷地区
      this.$http({
        url: '/api/v2/public/paper_district',
        method: 'get'
      }).then(res => {
        this.districtOptions = res.data.list
      })
    },
    async initData (page, limit) {
      if (!page) {
        page = this.page
      }
      if (!limit) {
        limit = this.size
      }
      const { data } = await getRuleList({
        page, limit,
        ...this.form
      })
      this.tableData = data.list
      this.totalElements = data.count
      this.$refs.pagination.pageSize = this.size
      this.$refs.pagination.currentPage = this.page
      if (this.heightLightIndex !== '') {
        this.$refs.TablesRef.setCurrentRow(this.tableData[this.heightLightIndex])
        this.heightLightIndex = ''
      }
    },

    add () {
      this.$refs.templateDialogRef.dialogVisible = true
      this.$refs.templateDialogRef.title = '添加组卷规则模板'
    },
    importFile () {
      // 导入
      let file;
      this.$get_file({ limit: 1000, fileType: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }).then(res => {
        file = res
        let formData = new FormData()
        formData.append('file', file)
        formData.append('sign', this.$getMd5Form(file))
        this.$http({
          url: '/api/v2/paper/import_paper_rule',
          method: 'post',
          data: formData
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.search()
        })
      })
    },

    search () {
      this.$refs.pagination.toFirstPage()
    },

    onDelete (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delRule({
          paper_rule_id: row.paper_rule_id
        })
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '删除成功!',
          type: 'success'
        })
        this.initData()
      })
      // this.$message.success("操作成功")
    },
    onEdit (row) {
      let form = {
        paper_district_id: row.paper_district_id,
        subject_id: row.subject_id,
        rule_name: row.rule_name,
        paper_rule_id: row.paper_rule_id,
        status: row.status
      }
      this.$refs.templateDialogRef.dialogVisible = true
      this.$refs.templateDialogRef.title = '修改组卷规则模板'
      this.$refs.templateDialogRef.form = form
    },

    // addDetail (row) {
    //   this.$refs.detailDialogRef.rowInfo = row
    //   this.$refs.detailDialogRef.dialogVisible = true
    // },
    toDetail (row, index) {
      this.setSessionInfo(index)
      this.$router.push('/paperRuleTemplateDetail?paper_rule_id=' + row.paper_rule_id + "&subject_id=" + row.subject_id + '&paper_district_id=' + row.paper_district_id)
    },
    // toAddDetail(){
    //    this.$router.push('/paperRuleTemplateDetail?paper_rule_id=' + row.paper_rule_id + "&subject_id=" + row.subject_id + '&paper_district_id=' + row.paper_district_id)
    // },
    refresh () {
      this.initData()
    },
    toPaper (row, index) {
      console.log(index)
      this.setSessionInfo(index)
      this.$router.push(`/paperRuleTemplate/paperinfo?paper_rule_id=${row.paper_rule_id}`)
    },
    // 缓存查询信息
    setSessionInfo (index) {
      let params = {
        form: this.form,
        limit: this.size,
        page: this.page,
        heightLightIndex: index
      }
      window.sessionStorage.setItem(this.$route.path, JSON.stringify(params))
    }
  },
  mounted () {
    let sessionInfo = window.sessionStorage.getItem(this.$route.path)
    if (sessionInfo) {
      sessionInfo = JSON.parse(sessionInfo)
      this.form = sessionInfo.form
      this.page = sessionInfo.page
      this.size = sessionInfo.limit,
        this.heightLightIndex = sessionInfo.heightLightIndex
    }
    this.setTablesColums()
    this.getSubjectList()
    this.getDirList()
    this.initData()
  },
  beforeRouteEnter (to, from, next) {
    // 若不是从详情页面进入则清除缓存
    if (from.path != '/paperRuleTemplateDetail' && from.path != '/paperRuleTemplate/paperinfo') {
      next(vm => {
        window.sessionStorage.removeItem(vm.$route.path)
      })
    }
    else {
      next()
    }
  },
}
</script>

<style lang="scss" scoped>
:deep .el-button {
}
</style>